<template>
  <v-container fluid class="mt-5">
    <v-row justify="center">
      <v-col cols="11">
        <v-expand-transition>
          <Loader v-if="loading" />
          <v-row v-else>
            <CreateSymbol @created="getSymbols" />
            <UpdateSymbol :symbolData="selectedSymbol" @updated="getSymbols" />
            <DeleteSymbol :symbolData="selectedSymbol" @deleted="getSymbols" />

            <v-col
              cols="12"
              class="d-flex justify-end"
            >
              <v-tooltip bottom>
                <template v-slot:activator="{ on, attrs }">
                  <v-btn
                    color="primary"
                    height="56px"
                    class="ml-5"
                    v-bind="attrs"
                    v-on="on"
                    @click="createSymbol()"
                  >
                    <v-icon>
                      mdi-plus
                    </v-icon>
                  </v-btn>
                </template>
                <span>Cadastrar Símbolo</span>
              </v-tooltip>
            </v-col>

            <v-col
              lg="1"
              md="2"
              sm="3"
              cols="6"
              v-for="(symbol, index) in symbols"
              :key="symbol._id"
            >
              <v-card>
                <v-btn
                  fab
                  x-small
                  color="error"
                  class="delete-btn"
                  @click="deleteSymbol(symbol)"
                >
                  <v-icon>
                    mdi-delete
                  </v-icon>
                </v-btn>
                <div class="d-flex flex-no-wrap justify-center" @click="updateSymbol(symbol)">
                  <v-avatar
                    tile
                    left
                    size="80%"
                    color="#F7F7F7"
                    class="ma-3 symbol-image"
                  >
                    <v-img
                      v-if="symbol.image"
                      :src="symbol.image"
                      contain
                      max-height="40%"
                      @error="imageErrorHandler(index)"
                    />

                    <span
                      v-else
                      class="display-2 white--text text-center"
                    >
                      <v-icon>
                        mdi-shield-star
                      </v-icon>
                    </span>
                  </v-avatar>
                </div>
              </v-card>
            </v-col>

            <v-col
              v-if="symbols.length === 0"
              cols="12"
            >
              <EmptyData />
            </v-col>
          </v-row>
        </v-expand-transition>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
const imageOffIcon = require('@/assets/icons/image-off.png')

export default {
  components: {
    Loader: () => import('@/components/LoadingCards'),
    EmptyData: () => import('@/components/EmptyData'),
    CreateSymbol: () => import('./components/CreateSymbol.vue'),
    UpdateSymbol: () => import('./components/UpdateSymbol.vue'),
    DeleteSymbol: () => import('./components/DeleteSymbol.vue')
  },
  data () {
    return {
      loading: false,
      symbols: [],
      selectedSymbol: {}
    }
  },
  computed: {
    createShieldSymbolModal: {
      get () {
        return this.$store.state.createShieldSymbolModal
      },
      set (val) {
        this.$store.commit({
          type: 'SET_MODAL_STATE',
          modal: 'createShieldSymbolModal',
          value: val
        })
      }
    },
    updateShieldSymbolModal: {
      get () {
        return this.$store.state.updateShieldSymbolModal
      },
      set (val) {
        this.$store.commit({
          type: 'SET_MODAL_STATE',
          modal: 'updateShieldSymbolModal',
          value: val
        })
      }
    },
    deleteShieldSymbolModal: {
      get () {
        return this.$store.state.deleteShieldSymbolModal
      },
      set (val) {
        this.$store.commit({
          type: 'SET_MODAL_STATE',
          modal: 'deleteShieldSymbolModal',
          value: val
        })
      }
    }
  },
  mounted () {
    this.getSymbols()
  },
  methods: {
    getSymbols () {
      this.loading = true

      this.$http.get('/shield-symbols')
        .then((res) => {
          const { data } = res.data
          this.symbols = data
        })
        .catch((err) => {
          this.$toast.error(err.response.data.message)
        })
        .finally(() => (this.loading = false))
    },
    createSymbol () {
      this.createShieldSymbolModal = true
    },
    updateSymbol (symbol) {
      this.selectedSymbol = symbol
      this.updateShieldSymbolModal = true
    },
    deleteSymbol (symbol) {
      this.selectedSymbol = symbol
      this.deleteShieldSymbolModal = true
    },
    imageErrorHandler (index) {
      this.symbols[index].image = imageOffIcon
    }
  }
}
</script>

<style scoped>
.symbol-image {
  border: 1px solid #BDBDBD !important;
  border-radius: 3px !important;
}

.delete-btn {
  position: absolute;
  top: -10px;
  right: -10px;
  z-index: 5;
}

.delete-btn:hover {
  transform: scale(1.1);
}
</style>
